// Generated by Framer (d03ec02)

import { addFonts, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["U01jwQAY8"];

const serializationHash = "framer-FJAdq"

const variantClassNames = {U01jwQAY8: "framer-v-rhqpzn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "U01jwQAY8", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rhqpzn", className, classNames)} data-framer-name={"Google"} layoutDependency={layoutDependency} layoutId={"U01jwQAY8"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fit", intrinsicHeight: 409, intrinsicWidth: 480, pixelHeight: 409, pixelWidth: 480, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/eSmllbdrIaxCLjAW0LPHHWpb9do.png"}} className={"framer-1lzdqbz"} data-framer-name={"Frame_4"} layoutDependency={layoutDependency} layoutId={"sIYJuuqac"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-FJAdq[data-border=\"true\"]::after, .framer-FJAdq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FJAdq.framer-mi66jm, .framer-FJAdq .framer-mi66jm { display: block; }", ".framer-FJAdq.framer-rhqpzn { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 90px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 178px; }", ".framer-FJAdq .framer-1lzdqbz { aspect-ratio: 1.9777777777777779 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 90px); overflow: visible; position: relative; width: 178px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FJAdq.framer-rhqpzn { gap: 0px; } .framer-FJAdq.framer-rhqpzn > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-FJAdq.framer-rhqpzn > :first-child { margin-left: 0px; } .framer-FJAdq.framer-rhqpzn > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 90
 * @framerIntrinsicWidth 178
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerORb4bGIGD: React.ComponentType<Props> = withCSS(Component, css, "framer-FJAdq") as typeof Component;
export default FramerORb4bGIGD;

FramerORb4bGIGD.displayName = "Google";

FramerORb4bGIGD.defaultProps = {height: 90, width: 178};

addFonts(FramerORb4bGIGD, [])